<template>
  <!-- <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-btn color="primary" @click="setLayout('user-layout')">Simple Layout</v-btn>
      <v-btn color="secondary" @click="setLayout('admin-layout')">App Layout</v-btn>
      <div class="">Not found</div>
    </v-layout>
  </v-container> -->
  <div class="notfound">
    <h2>404 NOT FOUND | ไม่พบหน้าที่ท่านต้องการ</h2>
    <v-btn @click="backHome">กลับหน้าหลัก</v-btn>
  </div>
</template>

<script>
export default {
  mounted() {
      this.$store.commit('SET_LAYOUT', 'login-layout');
  },
  methods: {
    backHome() {
      this.$router.push("/")
      this.setLayout('user-layout') 
      },
      setLayout(layout) {
        this.$store.commit('SET_LAYOUT', layout);
      }
  }
}
</script>

<style scoped>
  .notfound {
    text-align: center;
    margin: auto;
    padding: 25% 25%;
    background-color: grey;
    color: white;
    width: 100%;
    height: 100%;
  }
</style>

