<template>
  <div class="Home">
    <div class="cos">
      <!-- <button v-on:click="send(food)">ดาวโหลดรายงาน</button> -->

      <h2 class="green darken-1 white--text display-1">
        รายงานสูตรอาหาร
        <v-icon dark @click="send(foods)">mdi-cloud-download-outline</v-icon>
      </h2>
      <v-toolbar
        dark
        class="green darken-1 white--text display-1"
        style="margin-bottom: 3%;"
        :z-index="999"
      >
        <v-autocomplete
          v-model="selected_report"
          :items="listOfReport"
          clearable
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="โปรดเลือกประเภทรายงานที่ต้องการ"
          solo-inverted
        ></v-autocomplete>

        <v-autocomplete
          v-show="booType"
          v-model="select_type"
          :items="typeOfFood"
          clearable
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="ประเภทอาหาร"
          solo-inverted
        ></v-autocomplete>

        <v-autocomplete
          v-show="booName"
          v-model="select_name"
          :items="nameOfFood"
          clearable
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="ชื่ออาหาร"
          solo-inverted
        ></v-autocomplete>

        <v-autocomplete
          v-show="booOwner"
          v-model="select_owner"
          :items="owner"
          clearable
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="เจ้าของสูตรอาหาร"
          solo-inverted
        ></v-autocomplete>

        <v-row class="mt-4 ml-4 mr-2" v-show="booDate">
          <v-col cols="12" sm="6" md="12">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Picker without buttons"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-toolbar>

      <template>
        <v-row justify="center">
          <v-expansion-panels popout focusable>
            <v-expansion-panel v-for="(item, i) in foods" :key="i">
              <v-expansion-panel-header
                disable-icon-rotate
                class="green lighten-2 white--text font-weight-black"
              >
                <div class="">
                  <v-btn icon color="pink" @click.native.stop="onSomeThing">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  {{ item.Food_Name }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          ชื่ออาหาร
                        </th>
                        <th class="text-left">
                          วัตถุดิบ
                        </th>
                        <th class="text-left">
                          ขั้นตอนการทำ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">{{ item.Food_Name }}</td>
                        <td class="text-left">
                          <p
                            v-for="(ingre, itemObj) in food[i].ingre"
                            :key="itemObj"
                          >
                            {{ itemObj + 1 }}.) {{ ingre.used_name }}
                          </p>
                        </td>
                        <td class="text-left">
                          <p
                            v-for="(howto, itemObj) in food[i].howto"
                            :key="itemObj"
                          >
                            {{ itemObj + 1 }}.) {{ howto.Descriptions }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
// import pdfFonts from "pdfmake/build/examples/custom-fonts.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  name: "Home",
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      num: 0,
      food: [],
      selected_report: null,
      listOfReport: ["ประเภทอาหาร", "ชื่ออาหาร", "เจ้าของสูตรอาหาร", "วันที่แก้ไข"],
      booType: false,
      booName: false,
      booOwner: false,
      booDate: false,
      // owner: null,
      // nameOfFood: null,
      // typeOfFood: null,
      select_owner: "",
      select_name: "",
      select_type: "",
      menu2: false,
    };
  },
  computed: {
    foods: function() {
      return this.food.filter((name) => {
        if (this.booName) {
          return name.Food_Name.match(this.select_name);
        } 
        else if (this.booType) {
          return name.FoodType_Name.match(this.select_type);
        }
         else if (this.booDate) {
          return name.updated_at.match(this.date);
        } else {
          return name.Owner.match(this.select_owner);
        }
      });
    },
  },
  watch: {
    selected_report(val) {
      this.booType = false;
      this.booName = false;
      this.booOwner = false;
      this.booDate = false;
      this.select_owner = "";
      this.select_name = "";
      this.select_type = "";

      if (val == "ประเภทอาหาร") {
        this.booType = true;
        // alert("booType is : " + this.booType)
      } else if (val == "ชื่ออาหาร") {
        this.booName = true;
      } else if (val == "เจ้าของสูตรอาหาร") {
        this.booOwner = true;
      } else if (val == "วันที่แก้ไข") {
        this.booDate = true;
      } else {
        this.booType = false;
        this.booName = false;
        this.booOwner = false;
        this.booDate = false;
      }
    },
    // select_name: (val) => {
    //   alert(val)
    // this.dataFilter.selected_name = val
    //   alert(this.nameOfFood)
    // }
    // select_name (val) {
    //   this.dataFilter.selected_name = val
    //   console.log(this.dataFilter)
    // }
  },
  methods: {
    onSomeThing() {},
    send(food_report) {
      //setfont
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-MediumItalic.ttf",
        },
        Sarabun: {
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Bold.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-BoldItalic.ttf",
        },
      };

      var rows = [];
      var ingreinrow = "";
      var howtoinrow = "";
      rows.push(["รหัส", "ชื่ออาหาร", "วัตถุดิบ", "วิธีทำ"]);
      Object.keys(food_report).forEach(async (key) => {
        // console.log(food_report[key].Food_Name);

        Object.keys(food_report[key].ingre).forEach(async (i) => {
          console.log(food_report[key].ingre[i]);
          ingreinrow = ingreinrow.concat(
            parseInt(i) + 1,
            food_report[key].ingre[i].used_name,
            "\n"
          );
        });
        Object.keys(food_report[key].howto).forEach(async (i) => {
          // console.log(food_report[key].howto[i].Descriptions)
          howtoinrow = howtoinrow.concat(
            parseInt(i) + 1,
            food_report[key].howto[i].Descriptions,
            "\n"
          );
        });

        rows.push([
          food_report[key].Food_Id,
          food_report[key].Food_Name,
          ingreinrow,
          howtoinrow,
        ]);
      });
      // console.log(ingreinrow);
      // console.log(howtoinrow);

      var docDefinition = {
        content: [
          { text: "รายงานอาหาร" },
          {
            style: "tableExample",
            table: {
              widths: [50, 150, 100, 180],
              body: rows,
            },
          },
        ],
        defaultStyle: {
          font: "Sarabun",
        },
      };
      // pdfMake.createPdf(docDefinition).open({}, window);
      pdfMake.createPdf(docDefinition).download("รายงาน");

      // axios
      //       .post(
      //         "https://food.makecodeonline.com/Tontan_Api/Report/printReport.php",
      //         qs.stringify({ report: food_report })
      //       )
      //       .then((res) => {
      //        console.log(res.data.response);
      //       });

      // window.open("https://food.makecodeonline.com/Tontan_Api/Report/printReport.php?report="+food_report+"", "_blank");
    },
  },
  mounted() {
    axios
    // .get("http://localhost/Tontan_Api/Admin/report/selectReport.php")
      .get("https://food.makecodeonline.com/Tontan_Api/Admin/report/selectReport.php")
      .then((res) => {
        this.owner = res.data.Owner.map((obj) => {
          console.log(this.owner)
          return obj.Owner;
        });
        this.nameOfFood = res.data.FoodName.map((obj) => {
          console.log(this.nameOfFood)
          return obj.Food_Name;
        });
        this.typeOfFood = res.data.FoodType.map((obj) => {
          console.log(this.typeOfFood)
          return obj.FoodType_Name;
        });
      });

    axios
          // .get("http://localhost/Tontan_Api/Report/report.php")
      .get("https://food.makecodeonline.com/Tontan_Api/Report/report.php")
      .then((res) => {
        this.food = res.data.response;

        //เอาไอดีอาหารไปเช็ควัตถุดิบทั้งหมด เอามาใส่ใน food[ingre[]] แล้ว วนลูปตามจำนวนอาหาร

        Object.keys(res.data.response).forEach(async (key) => {
          //วนหาวัตถุดิบ
          axios
            .post(
              "https://food.makecodeonline.com/Tontan_Api/Report/findIngre.php",
              qs.stringify({ food_id: res.data.response[key].Food_Id })
            )
            .then((res) => {
              var ingre = res.data.response;
              this.food[key].ingre = ingre;
              //วนใส่ingre ในfood[ingre[]]
            });
        });

        Object.keys(res.data.response).forEach(async (key) => {
          //วนหาวิธีทำ
          axios
            .post(
              "https://food.makecodeonline.com/Tontan_Api/Report/findHowto.php",
              qs.stringify({ food_id: res.data.response[key].Food_Id })
            )
            .then((res) => {
              var howto = res.data.response;
              this.food[key].howto = howto;
              //วนใส่ingre ในfood[ingre[]]
            });
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
        let notfound = document.querySelector("#Not");
        notfound.innerHTML = "Something when wrong";
      });
  },
};
</script>
<style lang="scss" scoped>
.Home {
  display: inline-block;
  width: 90%;
  height: 100%;
  //   margin: 2% 14%;
  margin: 2% 0;
}
.cos {
  width: 100%;
  height: 100%;
}
</style>
