<template>
  <div class="" style="width: 88rem">
    <div class="text-center">
      <v-overlay :value="loading" :z-index="250">
        <v-progress-circular
          :size="70"
          :width="7"
          :z-index="250"
          color="amber"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <v-data-table :headers="headers" :items="FoodLists" :search="search" class="elevation-1">
      <!-- <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    > -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>รายการอาหาร</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหารายการ"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                เพิ่มรายการอาหารใหม่
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="green darken-3" dark>
                <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
              <v-form ref="entryForm" @submit.prevent="submitHandler" id="check-login-form">
                        <!-- :rules="[(v) => !!v || 'กรุณาเลือกสัญชาติอาหาร']" -->
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="national"
                        :items="nations"
                        item-text="text"
                        :rules="numberRules"
                        label="สัญชาติ"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="type"
                        :items="types"
                        item-text="text"
                        label="ประเภทอาหาร"
                        :rules="numberRules"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="name"
                        label="ชื่ออาหาร"
                        :rules="numberRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- v-model="ingrediens[k]" หาก insert ไม่ได้ให้ใช้อันนี้-->
                  <v-row v-for="(ingredien, k) in ingrediens" :key="'A' + k">
                    <v-col cols="12" sm="6" md="11">
                      <v-text-field
                        v-model="ingrediens[k]"
                        label="วัตถุดิบ"
                        :rules="numberRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="1">
                      <v-icon
                        @click="remove_ingre(k)"
                        v-show="k || (!k && ingrediens.length > 1)"
                        style="color:red;"
                        >remove_circle</v-icon
                      >
                      <v-icon
                        @click="add_ingre(k)"
                        v-show="k == ingrediens.length - 1"
                        style="color:green;"
                        >add_circle</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row v-for="(step, k) in steps" :key="k">
                    <v-col cols="12" sm="6" md="11">
                      <v-text-field
                        v-model="steps[k]"
                        label="วิธีทำ"
                        :rules="numberRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="1">
                      <v-icon
                        @click="remove_step(k)"
                        v-show="k || (!k && steps.length > 1)"
                        style="color:red;"
                        >remove_circle</v-icon
                      >
                      <v-icon
                        @click="add_step(k)"
                        v-show="k == steps.length - 1"
                        style="color:green;"
                        >add_circle</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="onwer"
                        label="เจ้าของสูตรอาหาร"
                        :rules="numberRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="insert">
                    <v-file-input
                      accept="image/jpg, image/jpeg"
                      label="อัพโหลดรูปภาพหน้าปก"
                      prepend-icon="mdi-camera"
                      v-model="cover_img"
                      :rules="numberRules.concat(coverCheck)"
                    ></v-file-input>
                  </v-row>
                  <v-row v-if="insert">
                    <v-file-input
                      multiple
                      accept="image/jpg, image/jpeg"
                      label="อัพโหลดรูปภาพ"
                      prepend-icon="mdi-camera"
                      v-model="photos"
                      :rules="numberRules.concat(fileCheck)"
                    ></v-file-input>
                  </v-row>
                  <v-row v-if="insert">
                    <v-file-input
                      show-size
                      accept="video/mp4"
                      label="อัพโหลดคลิปวีดีโอ"
                      v-model="video"
                      :rules="numberRules"
                    ></v-file-input>
                  </v-row>
                  <div class="" v-else>
                    <h2>รูปภาพหน้าปก</h2>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <v-row style="position: relative;">
                      <img
                        :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + cover_img"
                        style="display: flex;"
                        width="100%"
                        height="100%"
                      />
                    </v-row>
                    <br />
                    <v-row>
                      <v-file-input
                        label="แก้ไขภาพหน้าปก"
                        prepend-icon="mdi-camera"
                        v-model="cover_update"
                        :rules="coverCheck"
                      ></v-file-input>
                    </v-row>
                    <!-- <v-divider></v-divider> -->
                    <br />
                    <h2>รูปภาพ</h2>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <v-row style="position: relative;">
                      <!-- <img :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + photo" alt="" v-for="(photo, k) in photos" :key="'C' + k"> -->
                      <v-col v-for="(photo, h) in photos" :key="h">
                        <v-btn
                          class="red"
                          style="position: absolute;"
                          height="20px"
                          @click="deleteImage(index_photo[h], photo, h)"
                          >x</v-btn
                        >
                        <br />
                        <img
                          :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + photo"
                          style="display: flex;"
                          width="100px"
                          height="100px"
                        />
                      </v-col>
                    </v-row>
                    <br />
                    <v-row>
                      <v-file-input
                        multiple
                        label="เพิ่มรูปภาพ"
                        prepend-icon="mdi-camera"
                        v-model="photo_update"
                        :rules="fileCheck"
                      ></v-file-input>
                    </v-row>
                    <!-- <v-divider></v-divider> -->
                    <br />
                    <h2>คลิปวีดีโอ</h2>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <v-row>
                      <iframe
                        width="560"
                        height="300"
                        :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + video"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </v-row>
                    <v-row>
                      <v-file-input
                        show-size
                        label="อัพโหลดคลิปวีดีโอใหม่"
                        v-model="video_update"
                      ></v-file-input>
                    </v-row>
                  </div>
                  <v-row>
                    <v-textarea
                      v-model="descript"
                      label="รายละเอียดเพิ่มเติม"
                      counter
                      maxlength="120"
                      full-width
                      single-line
                      :rules="numberRules"
                      required
                    ></v-textarea>
                  </v-row>
                </v-container>
                <!-- <v-btn type="submit" color="success" v-if="insert">Submit</v-btn> -->
              </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  ปิดหน้าต่าง
                </v-btn>
                <!-- <v-btn color="blue darken-1" text @click="save" v-if="insert"> -->
                <!-- <v-btn color="blue darken-1" text @click="save" v-if="insert">
                  บันทึก
                </v-btn> -->
                <!-- <v-form ref="entryForm" @submit.prevent="submitHandler"> -->
                <v-btn type="submit" color="success" v-if="insert" form="check-login-form" @click="insertBtn = true">Submit</v-btn>
                <v-btn type="submit" color="blue darken-1"  form="check-login-form" @click="editBtn = true" v-else>
                  แก้ไข
                </v-btn>
                <!-- </v-form> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>


      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item.Food_Id)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.Food_Id)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import jwt_decode from "jwt-decode";

export default {
  data: () => ({
    loading: false,
    dialog: false,
    dialogDelete: false,
    filenames: [],
    search: '',
    headers: [
      {
        text: "รหัสอาหาร",
        align: "start",
        sortable: true,
        value: "Food_Id",
      },
      {
        text: "ชื่ออาหาร",
        value: "Food_Name",
      },
      { text: "สัญชาติ", value: "Nation_Name" },
      // { text: "สัญชาติ", value: "Nation_Id" },
      { text: "จำนวนผู้เข้าชม", value: "visitor" },
      { text: "เจ้าของสูตรอาหาร", value: "Owner" },
      { text: "เพิ่มโดย", value: "name" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    insert: 1,
    national: 1,
    type: 1,
    name: null,
    ingrediens: [""],
    steps: [""],
    onwer: null,
    cover_img: null,
    photos: null,
    video: null,
    descript: null,
    nations: [],
    types: [],
    FoodLists: [],
    index_photo: [],
    cover_update: null,
    photo_update: [],
    video_update: null,
    food_id: 0,
    id: 0,
    numberRules:[],
    editBtn: false,
    insertBtn: false
  }),
  mounted() {
    // สำหรับดึงสัญชาติมาเป็นตัวเลือก
    // alert(this.$store.state.auth.status.loggedIn)
    // let tmpUser = this.$store.state.auth.user
    // this.tmpUser = jwt_decode(tmpUser)
    // alert(this.tmpUser.data.email)
    var tmpUser = this.$store.state.auth.user;
     if (this.$store.state.auth.user != null) {
      this.tmpUser = jwt_decode(tmpUser);
      this.id = this.tmpUser.data.id;
    }
    axios
      .get("https://food.makecodeonline.com/Tontan_Api/Admin/data4Insert.php")
      .then((res) => {
        this.nations = res.data.response.map((response) => ({
          value: parseInt(response.Nation_ID),
          text: response.Nation_Name,
        }));
        this.types = res.data.type.map((type) => ({
          value: parseInt(type.FoodType_ID),
          text: type.FoodType_Name,
        }));
      })
      .catch((err) => {
        console.log(err.response.data.message);
        let notfound = document.querySelector("#Not");
        notfound.innerHTML = "Something when wrong";
      });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "เพิ่มรายการอาหาร" : "แก้ไขรายการอาหาร";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    name () {
      this.numberRules = []
    },
    insertBtn: () => {
      this.submitHandler()
    },
    editBtn: () => {
      this.submitHandler()
    }
  },

  created() {
    this.initialize();
    this.loading = false;
  },

  methods: {
     submitHandler () {
      this.numberRules = [
        v => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
        // v => /^\d+$/.test(v) || 'Must be a number',
      ],
      this.fileCheck = [
        files => !files || !files.some(file => file.size > 2_097_152) || 'ขนาดรูปภาพต้องไม่เกิน 2 Mb'
      ],
      this.coverCheck = [
        value => !value || value.size < 2000000 || 'ขนาดรูปภาพหน้าปกต้องไม่เกิน 2 Mb',
      ]
      var self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()){
          // self.save()
          if(self.insertBtn == true) {
            self.save()
          }
          if(self.editBtn == true) {
            self.submitUpdate()
          }
        }  
      })
      
      // alert('helloworld')
    },
    initialize() {
      var vm = this;
      vm.loading = true;
      axios
        .get("https://food.makecodeonline.com/Tontan_Api/overall/index2.php")
        .then((res) => {
          vm.FoodLists = res.data.response;
        })
        .catch((err) => {
          console.log(err.response.data.message);
          let notfound = document.querySelector("#Not");
          notfound.innerHTML = "Something when wrong";
        });
    },
    async deleteImage(id, path_img, h) {
      // console.log(id);
      // console.log(path_img);
      if(this.photos.length > 1) {
        this.photos.splice(h, 1);
        axios
          .post(
            "https://food.makecodeonline.com/Tontan_Api/Admin/update_delete_img.php",
            qs.stringify({
              f_id: this.food_id,
              photo_id: id,
              path: path_img,
            })
          )
          .then((res) => {
            console.log(res);
            this.index_photo.splice(h, 1);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // alert("ไม่สามารถลบรูปภาพทั้งหมดได้")
         this.$swal.fire({
            icon: "warning",
            title: "ไม่สามารถลบรูปภาพได้",
            text: "กรุณาเพิ่มรูปภาพเพิ่มเติม และแก้ไขรายการอาหารอีกครั้ง"
          })
      }
    },
    editItem(item) {
      this.food_id = item;
      var vm = this;
      vm.editedIndex = 1;
      vm.insert = 0;
      axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/Admin/read_update.php",
          qs.stringify({ id: item })
        )
        .then((res) => {
          console.log(res);
          vm.national = vm.nations.find(
            (item) => item.value === parseInt(res.data.header[0].Nation_Id)
          );
          vm.national = parseInt(vm.national.value);
          vm.type = vm.types.find(
            (item) => item.value === parseInt(res.data.header[0].FoodType_ID)
          );
          vm.type = parseInt(vm.type.value);
          vm.name = res.data.header[0].Food_Name;
          vm.onwer = res.data.Owner[0].Owner;
          vm.ingrediens = [];
          for (let index = 0; index < res.data.ingrediens.length; index++) {
            vm.ingrediens.push(res.data.ingrediens[index].used_name);
          }
          vm.steps = [];
          for (let index = 0; index < res.data.howtos.length; index++) {
            vm.steps.push(res.data.howtos[index].Descriptions);
          }

          vm.cover_img = res.data.Owner[0].cover_img;

          vm.index_photo = [];
          vm.photos = [];
          for (let index = 0; index < res.data.images.length; index++) {
            vm.index_photo.push(res.data.images[index].Photo_Id);
            vm.photos.push(res.data.images[index].path);
          }
          // console.log(vm.index_photo);
          // vm.video_update = res.data.video[0].path;
          vm.video = res.data.video[0].path;
          vm.descript = res.data.description[0].Description;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialog = true;
    },
    async submitUpdate() {
      var el = this;
      el.loading = true;

      let formData = new FormData();
      formData.append("id_food", el.food_id);
      formData.append("nation", el.national);
      formData.append("type", el.type);
      formData.append("name", el.name);
      for (let ingredien of el.ingrediens) {
        formData.append("ingrediens[]", ingredien);
      }
      for (let step of el.steps) {
        formData.append("steps[]", step);
      }
      formData.append("owner", el.onwer);
      formData.append("cover", el.cover_update);

      for (let file of el.photo_update) {
        formData.append("files[]", file, file.name);
      }
      formData.append("video", el.video_update);
      formData.append("descript", el.descript);
      // alert(formData);
      await axios
        .post("https://food.makecodeonline.com/Tontan_Api/Admin/submitUpdate.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.loading = false
          this.editBtn = false
          // this.$swal(res.data.message);
          this.$swal.fire({
            icon: "success",
            title: "แก้ไขรายการอาหารสำเร็จ"
          }).then(() => {
            // location.reload()
            this.initialize()
            // พรุ่งนี้ทำการเปลี่ยน location.reload() เป็น this.$router.go() เพื่อแก้ปัญหา reload
            // ต้องทำการสร้างหน้าสำหรับ 404 และทำปุ่มย้อนกลับเพื่อลดการ reload 
            this.loading = false
          })
          this.dialog = false;
          // this.$router.go();
        })
        .catch(() => {
          // console.log(err);
          this.$swal.fire({
            icon: "error",
            title: "การแก้ไขรายการอาหารไม่สำเร็จ"
          })
        });
    },
    deleteItem(item) {
      this.food_id = item;
      this.$swal
        .fire({
          title: "คุณต้องการลบรายการอาหารนี้ ?",
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          // denyButtonText: "No",
          cancelButtonText: "No",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            // denyButton: "order-3",
          },
          icon: "warning"
        })
        .then((result) => {
          if (result.isConfirmed) {
          // this.$swal.fire("Saved!", "", "success");
          this.deleteItemConfirm(item);
          } 
        });
    },
    async deleteItemConfirm(item) {
      await axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/Admin/deleteFood.php",
          qs.stringify({ id: item })
        )
        .then(() => {
          // console.log(res.data.message);
          this.$swal.fire({
            icon: "success",
            title: "ลบรายการอาหารเรียบร้อย"
          }).then(() => {
            location.reload();
          })
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "ลบรายการอาหารไม่สำเร็จ"
          })
        });
      await this.closeDelete();
      // await this.$router.go();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.insert = 1;
        this.national = 1;
        this.type = 1;
        this.name = null;
        this.ingrediens = [""];
        this.steps = [""];
        this.onwer = null;
        this.cover_img = null;
        this.photos = []; //เอาออกหากอยากให้ statefull
        this.cover_update = null;
        this.photo_update = [];
        this.video_update = null;
        this.video = null;
        this.descript = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      // if (this.photos) {
      var el = this;
      el.loading = true;

      let formData = new FormData();
      formData.append("id", el.id)
      formData.append("nation", el.national);
      formData.append("type", el.type);
      formData.append("name", el.name);
      for (let ingredien of el.ingrediens) {
        formData.append("ingrediens[]", ingredien);
      }
      for (let step of el.steps) {
        formData.append("steps[]", step);
      }
      formData.append("owner", el.onwer);
      formData.append("cover_img", el.cover_img);

      for (let file of el.photos) {
        formData.append("files[]", file, file.name);
      }
      formData.append("video", el.video);
      formData.append("descript", el.descript);

      await axios
        .post("https://food.makecodeonline.com/Tontan_Api/Admin/insert2Db.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.loading = false;
          el.insertBtn = false

          // this.$swal(res.data.message)
          this.$swal({
            title: "สำเร็จ",
            // text: res.data.message,
            text: "เพิ่มรายการอาหารสำเร็จ",
            type: "success",
            icon: "success",
          }).then(function() {
            // this.$router.go();
            location.reload();
          });
          this.dialog = false;
          // this.$router.go();
        })
        .catch((err) => {
          // this.$swal(err.res.data.message);
          // alert(err.res.data.message)
          console.log(err)
        });
      el.foods = null;
    },
    add_ingre() {
      this.ingrediens.push("");
    },
    remove_ingre(index) {
      this.ingrediens.splice(index, 1);
    },
    add_step() {
      this.steps.push("");
    },
    remove_step(index) {
      this.steps.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
