<template>
  <div>
    <v-app
      :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }"
      :dark="darkTheme"
      id="inspire"
    >
      <v-container>
        <v-layout wrap>
          <v-flex sm12 md6 offset-md3>
            <v-card elevation="10" light tag="section">
              <v-card-title>
                <v-layout align-center justify-space-between>
                  <h3 class="headline">
                    {{ platformName }}
                  </h3>
                </v-layout>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form>
                  <v-text-field
                    outline
                    hide-details
                    label="Password"
                    type="Password"
                    v-model="user.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
                <p class="incorrect" style="color:white; background-color: red; text-align:center;"></p>
              <v-divider></v-divider>
              <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :large="$vuetify.breakpoint.smAndUp"
                  @click="handleLogin()"
                >
                  ตรวจสอบ
                </v-btn>
                <v-btn
                  color="warning"
                  :large="$vuetify.breakpoint.smAndUp"
                  @click="clear"
                >
                  ล้างข้อมูล
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import User from "@/models/user";
import jwt_decode from "jwt-decode"

export default {
  data() {
    return {
      user: new User(""),
      darkTheme: true,
      platformName: "Admin Personal Setting",
      get_user: null,
      password: null,
      message: "",
    };
  },
  mounted() {
    this.user.password = null
    // console.log(this.get_user)
  },
  methods: {
    // setLayout(layout) {
      //   this.$store.commit("SET_LAYOUT", layout);
    // },
    handleLogin() {
      let incorrect = document.querySelector('.incorrect');
      this.get_user = this.$store.state.auth.user
      var decoded = jwt_decode(this.get_user)
      console.log(decoded.data.email)
      if(decoded.data.email) {
        this.user.email = decoded.data.email
      }
      // console.log(this.user.email)
      if (this.user.email && this.user.password) {
        // this.$store.dispatch("auth/logout");
      // if (this.user.email && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            // ถ้ามีปัญหาอะไรเกี่ยวกับระบบล็อกอิน ให้ไปเปิด loginfailed ใน auth.module.js
            // if(decoded.data.role === 1){
              this.$router.push("/changeData");
            //   this.setLayout("user-layout");
            //   // this.setLayout("admin-layout");
            // } else if(decoded.data.role === 2) {
            //   this.$router.push("/SuperAdminHome");
            //   this.setLayout("user-layout");
            // }
          },
          (error) => {
            incorrect.innerHTML = "รหัสผ่านไม่ถูกต้อง"
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }else{
        incorrect.innerHTML = "กรุณากรอกข้อมูลให้ครบถ้วน"
      }
    },
    clear(){
      this.user.password = null
    }
  },
};
</script>

<style lang="scss" scoped></style>
