<template>
  <div id="login-form">
    <!-- <v-app
      :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }"
      :dark="darkTheme"
      id="inspire"
    > -->
    <!-- <v-container> -->
    <v-layout wrap>
      <v-flex sm12 md6 offset-md3>
        <v-card elevation="4" light tag="section">
          <v-card-title>
            <v-layout align-center justify-space-between>
              <h3 class="headline">
                {{ platformName }}
              </h3>
              <!-- <v-flex>
                  <v-img :alt="platformName" class="ml-3" contain height="48px" position="center right" src="https://www.mobygames.com/images/i/12/25/1435075.png"></v-img>
                </v-flex> -->
            </v-layout>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!-- <p>Sign in with your username and password:</p> -->
            <v-form>
              <v-text-field
                outline
                label="Username"
                type="text"
                v-model="user.email"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
              <!-- <v-text-field
                    outline
                    label="Password"
                    type="password"
                    v-model="user.password"
                  ></v-text-field> -->

              <!--  -->
              <!-- <v-text-field
                    outline
                    hide-details
                    label="Password"
                    type="password"
                    v-model="user.password"
                  ></v-text-field> -->
              <v-text-field
                v-model="user.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Normal with hint text"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <p
            class="incorrect"
            style="color:white; background-color: red; text-align:center;"
          ></p>
          <v-divider></v-divider>
          <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
            <!-- <v-btn color="info" flat>
                Forgot password?
              </v-btn> -->
            <v-spacer></v-spacer>
            <!-- <v-btn color="info" :large="$vuetify.breakpoint.smAndUp" @click="setLayout('admin-layout')"> -->
            <v-btn
              color="primary"
              :large="$vuetify.breakpoint.smAndUp"
              @click="handleLogin()"
            >
              เข้าสู่ระบบ
            </v-btn>
            <v-btn
              color="primary"
              :large="$vuetify.breakpoint.smAndUp"
              @click="clear"
            >
              ล้างข้อมูล
            </v-btn>
            <!-- <router-link to="/">
                  <v-btn
                    color="error"
                    :large="$vuetify.breakpoint.smAndUp"
                    @click="setLayout('user-layout')"
                  >
                    ยกเลิก
                  </v-btn>
                </router-link> -->
            <v-btn
              color="error"
              :large="$vuetify.breakpoint.smAndUp"
              @click="back"
            >
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
    <!-- </v-app> -->
  </div>
</template>

<script>
import User from "@/models/user";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      user: new User("", ""),
      darkTheme: true,
      platformName: "Admin login",
      password: null,
      username: null,
      message: "",
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "รูปแบบของอีเมลไม่ถูกต้อง.";
        },
      },
    };
  },
  created() {
    this.setLayout("login-layout");
  },
  mounted() {
    this.user.password = null;
    // console.log(this.get_user)
  },
  methods: {
    setLayout(layout) {
      this.$store.commit("SET_LAYOUT", layout);
      // this.$router.push({path: '/addfood'})
    },
    handleLogin() {
      let incorrect = document.querySelector(".incorrect");
      if (this.user.email && this.user.password) {
        // console.log("have");
        this.$store.dispatch("auth/login", this.user).then(
          (res) => {
            let decoded = jwt_decode(res);
            if (decoded.data.role === 1) {
              this.$router.push("/addfood");
              this.setLayout("user-layout");
              // this.setLayout("admin-layout");
            } else if (decoded.data.role === 2) {
              this.$router.push("/SuperAdminHome");
              this.setLayout("user-layout");
            }
          },
          (error) => {
            incorrect.innerHTML = "อีเมลหรือรหัสผ่านไม่ถูกต้อง";
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        incorrect.innerHTML = "กรุณากรอกข้อมูลให้ครบถ้วน";
      }
    },
    clear() {
      // this.user.email = '',
      // this.user.password = ''
      (this.user.email = null), (this.user.password = null);
    },
    back() {
      this.setLayout("user-layout");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  #login-form {
    margin-top: 15%;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  #login-form {
    margin-top: 35%;
  }
}
@media screen and (min-width: 375px) and (max-width: 414px) {
  #login-form {
    margin-top: 47%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #login-form {
    margin-top: 40%;
  }
}
</style>
