<template>
  <div class="Home">
    <div class="cos">
      <!-- <v-carousel>
        <v-carousel-item
          v-for="(slide,i) in slides"
          :key="i"
          :src="require('@/assets/home_photo/' + slides[i].path)"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel> -->
      <v-carousel
        cycle
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="require('@/assets/home_photo/' + slides[i].path)"
          style="height: auto;"
        >
          <v-row class="fill-height" align="end" justify="center">
            <div
              class="font-weight-black green lighten-1 display-1 white--text"
              style="margin-bottom: 3%; padding: 1%"
            >
              {{ slide.label }}
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>

    <!-- ใช้ในการแสดงรายการอาหารยอดเข้าชมสูงสุด 15 รายการ -->
    <div class="subheader">
      <h2>รายการอาหารที่มีผู้เข้าชมมากที่สุด</h2>
    </div>
    <div class="content pt-4">
      <div>
        <v-sheet class="mx-auto" elevation="8" max-width="100%">
          <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
            <v-slide-item
              v-for="n in Foodlists"
              :key="n.list_id"
              v-slot="{ active }"
            >
              <!-- <v-slide-item v-for="n in 15" :key="n" v-slot="{ active }"> -->

              <!-- ใช้สำหรับแสดงรายการอาหาร และสามารถคลิกเพื่อเข้าชมสูตรอาหารได้ -->
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="200"
                width="200"
                @click="showMenu(n.Food_Id, n.Food_Name, n.Owner, n.path)"
              >
                <v-img
                  class="white--text align-end"
                  height="100%"
                  width="200px"
                  :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + n.path"
                >
                  <!-- :src="require('@/assets/' + n.path)" -->
                  <!-- รูปภาพจะต้องเก็บในโฟลเดอร์ให้เรียบร้อย โดยชื่อโฟลเดอร์คือ Id_ตามด้วย Food_Id ซึ่งเป็น Pk และใน Table ก็จะต้องเก็บพาร์ทดังนี้ เช่น Id_4/1.jpg -->
                  <!-- <v-img
                  class="white--text align-end"
                  height="100%"
                  width="200px"
                  :src="n.path"
                > -->
                </v-img>
                <v-row class="fill-height" align="center" justify="center">
                  <v-scale-transition>
                    <v-icon
                      v-if="active"
                      color="white"
                      size="48"
                      v-text="'mdi-close-circle-outline'"
                    ></v-icon>
                  </v-scale-transition>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <!-- ใช้สำหรับแสดงข้อมูลข้อความทั่วไปในหน้าแรกนี้ -->
      <div class="" v-for="(body, j) in bodies" :key="j" v-show="isY">
        <div
          class="mt-10 message"
          width="100%"
          height="100%"
          style="display:flex;"
          v-if="j % 2"
        >
          <div class="" style="text-align:left;" width="100%" height="100%">
            <div class="col-sm-12">
              <div class="a">
                <h3>{{ body.header }}</h3>
              </div>
              <div class="mt-6">
                <p>
                  {{ body.body }}
                </p>
                <v-btn
                  color="success"
                  class="ma-2 white--text"
                  href=" https://www.phetchaburicreativecity.com/"
                  target="_blank"
                >
                  เพิ่มเติม
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <img
              class="righ"
              :src="require('@/assets/home_photo/' + body.photo)"
              alt=""
              srcset=""
              height="100%"
              width="100%"
            />
          </div>
        </div>

        <div
          class="mt-10 message"
          width="100%"
          height="100%"
          style="display:flex;"
          v-else
        >
          <div class="col-sm-7" style="text-align: left;">
            <img
              class="lef"
              :src="require('@/assets/home_photo/' + body.photo)"
              alt=""
              srcset=""
              height="100%"
              width="100%"
            />
          </div>
          <div class="" style="text-align:right;" width="100%" height="100%">
            <div class="col-sm-12">
              <div class="a">
                <h3>{{ body.header }}</h3>
              </div>
              <div class="mt-6">
                <p>
                  {{ body.body }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-for="(body, j) in bodies" :key="j" v-show="!isY">
        <div
          class="mt-10 message"
          width="100%"
          height="100%"
          style="display:flex;"
        >
          <div class="" style="text-align:left;" width="100%" height="100%">
            <div class="col-sm-12">
              <div class="a">
                <h3>{{ body.header }}</h3>
              </div>
              <div class="col-sm-7">
                <img
                  class="righ"
                  :src="require('@/assets/home_photo/' + body.photo)"
                  alt=""
                  srcset=""
                  height="100%"
                  width="100%"
                />
              </div>
              <div class="mt-6">
                <p style="text-align: justify;">
                  {{ body.body }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ไดอร็อกสำหรับโชว์สูตรอาหารเมื่อมีการคลิกจากรายการอาหารยอดนิยม -->
    <v-dialog v-model="dialog" scrollable max-width="50%" v-if="isY">
      <v-card>
        <div class="image_slide">
          <v-carousel
            cycle
            height="300"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <template v-slot:prev="{ on, attrs }">
              <v-btn color="success" v-bind="attrs" v-on="on">ก่อนหน้า</v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-btn color="info" v-bind="attrs" v-on="on">ถัดไป</v-btn>
            </template>
            <v-carousel-item
              v-for="(item, k) in food_img"
              :key="k"
              style="text-align: center;"
            >
              <img
                :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + item.path"
                style="width: 100%; height: 300px;"
              />
            </v-carousel-item>
          </v-carousel>
        </div>
        <v-card-title>
          {{ food_name }}
          <v-spacer></v-spacer>
          <ion-icon name="book-outline"></ion-icon>

          <span style="font-size:12px;color: grey;">{{ visitor }}</span>
          <v-spacer></v-spacer>
          สูตรโดย :
          {{ Owner }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text height="100px">
          <v-card-subtitle><h3>วัตถุดิบที่จำเป็น</h3></v-card-subtitle>
          <ol>
            <li v-for="recipes in recipe" :key="recipes.Recipe_Id">
              {{ recipes.used_name }}
            </li>
          </ol>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle><h3>วิธีทำ</h3></v-card-subtitle>
          <ol>
            <li v-for="howtos in howto" :key="howtos.How_Id">
              {{ howtos.Descriptions }}
            </li>
          </ol>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle><h3>วีดีโอประกอบ</h3></v-card-subtitle>
          <div class="" v-if="video.length == 0">
            <h2>Video invalid</h2>
          </div>
          <div class="test" v-for="clip in video" :key="clip.Recipe_Id" v-else>
            <iframe
              v-if="dialog"
              width="560"
              height="300"
              :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + clip.path"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              embed
            ></iframe>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <p style="color: Silver;">แก้ไขล่าสุดเมื่อ : {{ updated_at }}</p>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="download">
            ดาวน์โหลด
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ปิดหน้าต่าง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" scrollable max-width="300px" v-else>
      <v-card>
        <v-card-title>{{ food_name }}
          <v-spacer></v-spacer>
          <i style="color: Silver; font-size: 1px; float: right; position: relative;">แก้ไขเมื่อ : {{ updated_at }}</i>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
           <v-card-subtitle><h3>วัตถุดิบที่จำเป็น</h3></v-card-subtitle>
          <ol>
            <li v-for="recipes in recipe" :key="recipes.Recipe_Id">
              {{ recipes.used_name }}
            </li>
          </ol>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle><h3>วิธีทำ</h3></v-card-subtitle>
          <ol>
            <li v-for="howtos in howto" :key="howtos.How_Id">
              {{ howtos.Descriptions }}
            </li>
          </ol>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle><h3>วีดีโอประกอบ</h3></v-card-subtitle>
          <div class="" v-if="video.length == 0">
            <h2>Video invalid</h2>
          </div>
          <div class="w_iframe" v-for="clip in video" :key="clip.Recipe_Id" v-else>
            <iframe
              v-if="dialog"
              :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + clip.path"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              embed
            ></iframe>
          </div>
          <v-divider></v-divider>
        <i>ขอบคุณสูตรจาก : {{Owner}}</i>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="download">
            ดาวน์โหลด
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ปิดหน้าต่าง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default {
  name: "Home",

  data() {
    return {
      model: null,
      dialog: false,
      slides: [],
      bodies: [],
      Foodlists: [],
      food_name: "",
      recipe: [],
      howto: [],
      video: [],
      Owner: "",
      food_img: [],
      visitor: 0,
      created_at: null,
      updated_at: null,
      base64: null,
      cover_img: null,
    };
  },
  mounted() {
    axios
      .get("https://food.makecodeonline.com/Tontan_Api/home/carousel.php")
      .then((res) => {
        this.slides = res.data.response;
      })
      .catch((err) => {
        console.log(err.response.data.message);
        let notfound = document.querySelector("#Not");
        notfound.innerHTML = "Something when wrong";
      });
    //
    axios
      .get("https://food.makecodeonline.com/Tontan_Api/home/bodies.php")
      .then((res) => {
        this.bodies = res.data.response;
        // console.log(this.bodies);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        let notfound = document.querySelector("#Not");
        notfound.innerHTML = "Something when wrong";
      });
    //
    axios
      .get("https://food.makecodeonline.com/Tontan_Api/home/recomented.php")
      .then((res) => {
        this.Foodlists = res.data.response;
        // console.log(this.Foodlists);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        let notfound = document.querySelector("#Not");
        notfound.innerHTML = "Something when wrong";
      });
  },
  methods: {
    getBase64ImageFromURL() {
      return new Promise((resolve, reject) => {
        var img = new Image();
        var co = "https://api.codetabs.com/v1/proxy?quest=";
        img.src =
          co +
          "https://food.makecodeonline.com/Tontan_Api/Admin/" +
          this.cover_img;
        img.crossOrigin = "Anonymous";

        img.onload = () => {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var imgWidth = img.width;
          var imgHeight = img.height;
          var scaleX = 1;
          var scaleY = 1;
          if (imgWidth > canvas.width) scaleX = canvas.width / imgWidth;
          if (imgHeight > canvas.height) scaleY = canvas.height / imgHeight;
          var scale = scaleY;
          if (scaleX < scaleY) scale = scaleX;
          if (scale < 1) {
            imgHeight = imgHeight * scale;
            imgWidth = imgWidth * scale;
          }
          var dx = (canvas.width - imgWidth) / 2;
          var dy = (canvas.height - imgHeight) / 2;

          ctx.beginPath();
          ctx.rect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = "white";
          ctx.fill();

          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            dx,
            dy,
            imgWidth,
            imgHeight
          );

          var dataURL = canvas.toDataURL("image/jpeg");

          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };
      });
    },
    async download() {
      var arr = this.recipe.map((se) => {
        return se.used_name;
      });
      var arr2 = this.howto.map((se) => {
        return se.Descriptions;
      });
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-MediumItalic.ttf",
        },
        Sarabun: {
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Bold.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-BoldItalic.ttf",
        },
      };

      var dd = {
        content: [
          {
            alignment: "justify",
            columns: [
              {
                stack: [
                  { text: this.food_name, style: "header" },
                  {
                    ul: [
                      { text: "สูตรอาหารโดย " + this.Owner, listType: "none" },
                    ],
                  },
                ],
              },
              {
                image: await this.getBase64ImageFromURL("myimage.jpg"),
                width: 200,
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 517,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
          {
            margin: [0, 20],
            style: "tableExample",
            table: {
              widths: [250, 250],
              headerRows: 1,
              body: [
                [
                  {
                    border: [false, false, false, true],
                    text: "วัตถุดิบที่สำคัญ",
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                    background: "#38383a",
                    color: "white",
                  },
                  {
                    border: [false, false, false, true],
                    text: "ขั้นตอนการทำ",
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                    background: "#38383a",
                    color: "white",
                  },
                ],
                [
                  {
                    ol: arr,
                    lineHeight: 1.5,
                  },
                  {
                    ol: arr2,
                    lineHeight: 1.5,
                  },
                ],
              ],
            },
          },
        ],
        imgages: {
          pho_to:
            "https://images.pexels.com/photos/209640/pexels-photo-209640.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=300",
        },
        styles: {
          header: {
            fontSize: 22,
            bold: true,
          },
          lef: {
            alignment: "left",
          },
          cen: {
            alignment: "center",
          },
          righ: {
            alignment: "right",
          },
        },
        defaultStyle: {
          font: "Sarabun",
          columnGap: 20,
        },
      };

      pdfMake.createPdf(dd).download("สูตร" + this.food_name);
    },
    async showMenu(id, name, own, path) {
      this.dialog = true;
      this.cover_img = path;
      await axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/overall/showMenu.php",
          qs.stringify({ id: id })
        )
        .then((res) => {
          this.food_name = name;
          this.Owner = own;
          this.recipe = res.data.response;
          this.food_img = res.data.img;
          this.howto = res.data.howto;
          this.video = res.data.video;
          this.created_at = res.data.response[res.data.response.lengtศศศh - 3];
          this.updated_at = res.data.response[res.data.response.length - 2];
          this.visitor = res.data.response[res.data.response.length - 1];
          this.recipe.splice(-3);
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          });
        });
    },
  },
  computed: {
    isY() {
      // alert(this.$vuetify.breakpoint.mdAndUp)
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>
<style lang="scss" scoped>
.Home {
  display: inline-block;
  width: 70%;
  height: 100%;
  margin: 2% 14%;
}
.cos {
  width: 100%;
  height: 100%;
}
.subheader {
  text-align: left;
  background-color: #00af91;
  color: white;
  padding-left: 2%;
  margin-top: 2%;
}
// @media (max-width: 800px) {
//   .Home {
//     flex-direction: flex;
//     width: 20%;
//     height: 100%;
//     left: 0;
//   }
// }
div.a {
  -webkit-text-decoration-line: overline; /* Safari */
  text-decoration-line: overline;
  text-decoration-style: solid;
  text-decoration-color: #00af91;
  text-decoration-thickness: 5px;
}
.righ {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.lef {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
// สำหรับ ip5
@media screen and (min-width: 320px) and (max-width: 480px) {
  .Home {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
  }
  .cos {
    width: 20em;
    height: auto;
    padding: 0.4em;
  }
  .subheader {
    width: 20em;
    margin: 1em 0;
  }
  .content {
    width: 20em;
  }
  .message {
    flex-direction: column;
  }
  .righ {
    mask-image: none;
  }
  .lef {
    mask-image: none;
  }
  .w_iframe {
    width: 100%;
  }
  .w_iframe iframe {
    width: 100%;
  }
}
</style>
