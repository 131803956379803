<template>
  <div class="" style="width: 88rem">
     <div class="text-center">
      <v-overlay :value="loading" :z-index="250">
        <v-progress-circular
          :size="70"
          :width="7"
          :z-index="250"
          color="amber"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <v-data-table :headers="headers" :items="Admins" class="elevation-1">
      <template v-slot:item.role_name="{ item }">
        <v-chip :color="getColor(item.role_name)" dark>
          {{ item.role_name }}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ผู้ใช้งาน</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                เพิ่มผู้ใช้งานใหม่
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="green darken-3" dark>
                <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row v-show="insert">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="name"
                        label="ชื่อผู้ใช้งาน"
                        :rules="[(v) => !!v || 'กรุณากรอกชื่อผู้ใช้งาน']"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-show="!insert">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="id"
                        label="รหัสผู้ใช้งาน"
                        disabled
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="name"
                        label="ชื่อผู้ใช้งาน"
                        :rules="[(v) => !!v || 'กรุณากรอกชื่อผู้ใช้งาน']"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="mail"
                        label="อีเมล"
                        :rules="[(v) => !!v || 'กรุณากรอกอีเมล']"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="password"
                        label="รหัสผ่าน"
                        :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่าน']"
                        required
                      ></v-text-field>
                      <!-- <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Normal with hint text"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="role"
                        :items="roles"
                        item-text="text"
                        :rules="[(v) => !!v || 'กรุณาเลือกประเภทผู้ใช้งาน']"
                        label="ประเภทผู้ใช้งาน"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  ปิดหน้าต่าง
                </v-btn>
                <!-- <v-btn color="blue darken-1" text @click="save" v-if="insert"> -->
                <v-btn color="blue darken-1" text @click="save" v-if="insert">
                  บันทึก
                </v-btn>
                <v-btn color="blue darken-1" text @click="submitUpdate" v-else>
                  แก้ไข
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >คุณต้องการผู้ใช้งานนี้หรือไม่ ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item.id)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
// import jwt_decode from "jwt-decode"

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    filenames: [],
    show1: false,
    loading: false,
    rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
    // search: "",
    headers: [
      {
        text: "รหัสพนักงาน",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "ชื่อผู้ใช้งาน",
        value: "name",
      },
      { text: "อีเมลผู้ใช้งาน", value: "email" },
      // { text: "สัญชาติ", value: "Nation_Id" },
      {
        text: "ประเภทผู้ใช้งาน",
        value: "role_name",
        // class: "success--text title",
      },

      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    insert: 1,
    id: null,
    name: null,
    mail: null,
    password: null,
    role: null,
    Admins: [],
    roles: [
      { text: "Admin", value: 1 },
      { text: "Super Admin", value: 2 },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "เพิ่มผู้ใช้งานใหม่" : "แก้ไขผู้ใช้งาน";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // editedIndex: (val) => {
    //   if (val === -1) {

    //   }
    // }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var vm = this;
      axios
        .get("https://food.makecodeonline.com/Tontan_Api/SuperAdmin/index.php")
        .then((res) => {
          vm.Admins = res.data.response;
        })
        .catch((err) => {
          console.log(err.response.data.message);
          let notfound = document.querySelector("#Not");
          notfound.innerHTML = "Something when wrong";
        });
    },
    getColor(value) {
      if (value == "ROLE_ADMIN") return "green";
      else if (value == "ROLE_SUPERADMIN") return "red";
      else return "green";
    },
    async deleteImage(id, path_img, h) {
      console.log(id);
      // console.log(path_img);
      this.photos.splice(h, 1);
      axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/admin/update_delete_img.php",
          qs.stringify({
            f_id: this.food_id,
            photo_id: id,
            path: path_img,
          })
        )
        .then((res) => {
          console.log(res);
          this.index_photo.splice(h, 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editItem(item) {
      this.id = item;
      // alert(this.id)
      var vm = this;
      vm.editedIndex = 1;
      vm.insert = 0;
      axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/SuperAdmin/query_user.php",
          qs.stringify({ id: item })
        )
        .then((res) => {
          // console.log(res);
          vm.name = res.data.user.name;
          vm.mail = res.data.user.email;
          vm.role = res.data.user.roles;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      this.dialog = true;
    },
    submitUpdate() {
      var el = this;
      el.loading = true;

      let formData = new FormData();
      formData.append("id", el.id);
      formData.append("name", el.name);
      formData.append("mail", el.mail);
      formData.append("password", el.password); // create variable for password
      formData.append("role", el.role);
      // alert(formData);
      axios
        .post("https://food.makecodeonline.com/Tontan_Api/SuperAdmin/editUser.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        // .then((res) => {
        //   this.loading = false;
        //   alert(res.data.message);
        //   this.dialog = false;
        //   this.$router.go();
        // })
        .then(() => {
          this.loading = false;
           this.$swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลผู้ใช้งานสำเร็จ"
          }).then(() => {
            location.reload()
          })
        })
        .catch(() => {
        // .catch((err) => {
          // console.log(err);
          this.$swal.fire({
            icon: "error",
            title: "การแก้ไขผู้ใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          })
        });
    },
    deleteItem(item) {
      this.id = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/SuperAdmin/delete_user.php",
          qs.stringify({ id: this.id })
        )
        .then(() => {
          this.dialogDelete = false;
          this.closeDelete();
           this.$swal.fire({
            icon: "success",
            title: "ลบผู้ใช้งานสำเร็จ"
          }).then(() => {
            location.reload()
          })
        })
        // .then((res) => {
        //   this.dialogDelete = false;
        //   this.closeDelete();
        //   alert(res.data.message);
        // })
        .catch(() => {
           this.$swal.fire({
            icon: "error",
            title: "ไม่สามารถลบผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง"
          })
        });
        // .catch((err) => {
        //   alert(err.response.data);
        // });
      // await this.$router.go();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.insert = 1;
        (this.id = null),
          (this.name = null),
          (this.mail = null),
          (this.password = null),
          (this.role = null);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      var el = this;
      el.loading = true

      let formData = new FormData();

      formData.append("name", el.name);
      formData.append("mail", el.mail);
      formData.append("password", el.password);
      formData.append("role", el.role);

      await axios
        .post("https://food.makecodeonline.com/Tontan_Api/SuperAdmin/AddUser.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false
          el.dialog = false;
          alert(res.data.message);
          el.close();
          el.$router.go();
        })
        .catch((err) => {
          alert(err.res.data.message)
        })
        // .then(function(response) {
        //   this.loading = false
        //   el.dialog = false;
        //   alert(response.data.message);
        //   el.close();
        //   el.$router.go();
        // })
        // .catch(function(error) {
        //   alert(error.response.data.message);
        // });
    },
    add_ingre() {
      this.ingrediens.push("");
    },
    remove_ingre(index) {
      this.ingrediens.splice(index, 1);
    },
    add_step() {
      this.steps.push("");
    },
    remove_step(index) {
      this.steps.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
