<template>
  <div>
    <v-app>
      <v-app-bar app color="green darken-3" dark>
        <div class="d-flex align-center">
          <div class="toggle">
            <ion-icon name="menu-outline" class="open"></ion-icon>
            <ion-icon name="close-outline" class="close"></ion-icon>
          </div>
          <router-link
            to="/addfood"
            style="text-decoration: none; color: inherit;"
            class="d-flex"
          >
           <v-img
              alt="Vuetify Logo"
              class="shrink ml-12 mr-2"
              contain
              src="../assets/unnamed.png"
              transition="scale-transition"
              width="50"
              height="50"
              style="border-radius: 50%; background-color: white;"
            />
            <!-- <h2>ตาลโตนด</h2> -->
            <h2 style="text-align: center; padding-top: 0.75%; text-shadow: 2px 2px #82c065;">ฐานข้อมูลอาหารคาวหวานจากตาลโตนดเมืองเพชร</h2>

          </router-link>
        </div>

        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          label="Search Menu"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field> -->
        <v-spacer></v-spacer>
      <!-- <v-btn @click="setLayout('user-layout')" color="success" text ><v-icon>logout</v-icon></v-btn> -->
       <router-link to="/">
      <v-btn @click.prevent="logOut" color="success" text ><v-icon>logout</v-icon></v-btn>
        </router-link>
      </v-app-bar>
      <div class="navigation">
        <ul>
          <li class="list active" v-if="admin">
            <b></b>
            <b></b>
            <router-link to="/addfood">
              <span class="icon"
                ><ion-icon name="file-tray-stacked-outline"></ion-icon
              ></span>
              <span class="title">หน้าแรก</span>
            </router-link>
          </li>
          <li class="list" v-if="admin">
            <b></b>
            <b></b>
            <router-link to="/report">
              <span class="icon"
                ><ion-icon name="newspaper-outline"></ion-icon></span>
              
              <span class="title">รายงาน</span>
            </router-link>
          </li>
        </ul>
      </div>

      <v-main>
        <div class="content">
          <router-view />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
// import jwt_decode from "jwt-decode"

export default {
  name: "Admin",

  data: () => ({
    search: "",
    admin: 0,
  }),
  mounted() {
    // check user
    // let tmpUser = this.$store.state.auth.user
    // this.tmpUser = jwt_decode(tmpUser)
    // this.admin = this.tmpUser.data.role


    let menuToggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let conten = document.querySelector(".content");
    menuToggle.onclick = function() {
      menuToggle.classList.toggle("active");
      navigation.classList.toggle("active");
      conten.classList.toggle("active");
    };

    // add active class in selected list item
    let list = document.querySelectorAll(".list");
    for (let i = 0; i < list.length; i++) {
      let vm = this;
      list[i].onclick = function() {
        if (i == 0) {
          // alert("ahel")
          vm.searchAble = false;
        } else {
          vm.searchAble = true;
        }
        let j = 0;
        while (j < list.length) {
          list[j++].className = "list";
        }
        list[i].className = "list active";
      };
    }
  },
  methods: {
    setLayout(layout) {
      this.$store.commit("SET_LAYOUT", layout);
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.setLayout('user-layout')
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Nunito");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  min-height: 100vh;
  background: #fff;
}
.content {
  width: 90rem;
  margin: 0 auto;
  margin-left: 6em;
  text-align: center;
}
.content.active {
  width: 90rem;
  margin: 0 auto;
  margin-left: 16em;
  // background-color: red;
  text-align: center;
}
.navigation {
  position: fixed;
  top: 47px;
  left: 0;
  bottom: 0;
  width: 70px;
  border-radius: 10px;
  box-sizing: initial;
  border-left: 5px solid #388e3c;
  background: #388e3c;
  transition: 0.5s;
  overflow-x: hidden;
  z-index: 1;
}
.navigation.active {
  width: 220px;
}
.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 5px;
  padding-top: 40px;
}
.navigation ul li {
  position: relative;
  list-style: none;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.navigation ul li.active {
  background: #fff;
}
.navigation ul li b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}
.navigation ul li b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  background: #388e3c;
}
.navigation ul li b:nth-child(2) {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}
.navigation ul li b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  background: #388e3c;
}
.navigation ul li.active b:nth-child(1),
.navigation ul li.active b:nth-child(2) {
  display: block;
}
.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #fff;
}
.navigation ul li.active a {
  color: #333;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.navigation ul li a .icon ion-icon {
  font-size: 1.5em;
}
.navigation ul li a .title {
  position: relative;
  display: block;
  padding-left: 10px;
  height: 60px;
  line-height: 60px;
  white-space: normal;
}
.toggle {
  position: fixed;
  top: 7px;
  left: 10px;
  width: 40px;
  height: 40px;
  // background: #00af91;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 2;
}
// .toggle.active {
//   background: #00af91;
// }
.toggle ion-icon {
  position: absolute;
  color: #fff;
  font-size: 34px;
  display: none;
}
.toggle ion-icon.open,
.toggle.active ion-icon.close {
  display: block;
}
.toggle ion-icon.close,
.toggle.active ion-icon.open {
  display: none;
}
.conten {
  width: 95%;
  height: 695px;
  margin-left: 76px;
}
.conten.active {
  width: 85.3%;
  height: 695px;
  margin-left: 226px;
}
</style>
