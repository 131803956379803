import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Food from '../views/Food.vue'
import Sweet from '../views/Sweet.vue'
import Beverage from '../views/Beverage.vue'
import Product from '../views/product.vue'
import Login from '../views/Admin/index.vue'
import Report from '../views/Admin/Report.vue'
import First from '../views/User/Home.vue'
import AdminFood from '../views/Admin/AddFood copy.vue'
import MyComponent from '../components/MyComponent.vue'
import SuperAdminHome from '../views/SuperAdmin/SuperAdminHome.vue'
import Log from '../views/SuperAdmin/log.vue'
import verifies from '../views/Admin_Setting/verify.vue'
import changeData from '../views/Admin_Setting/changeData.vue'

import jwt_decode from "jwt-decode"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'First',
    component: First
  },
  {
    path: '/overall',
    name: 'Home',
    component: Home
  },
  {
    path: '/food',
    name: 'Food',
    component: Food
  },
  {
    path: '/sweet',
    name: 'Sweet',
    component: Sweet
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/beverage',
    name: 'Beverage',
    component: Beverage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/addfood',
    name: 'AdminFood',
    component: AdminFood,
    beforeEnter(to, from, next) {
      // debugger
      let currentUser = JSON.parse(window.localStorage.user);
      let detail_user = jwt_decode(currentUser)
      if(currentUser && detail_user.data.role === 1) {
        next()
      } else {
        alert('คุณไม่มีสิทธิ์เข้าถึงหน้าเว็บนี้')
        router.go(-1)
        // next("/")
      }
    }
  },
  // {
    //   path: '/addfood1',
    //   name: 'AdminFood1',
    //   component: AdminFood1
    // },
    {
      path: '/report',
      name: 'Report',
      component: Report,
      beforeEnter(to, from, next) {
        let currentUser = JSON.parse(window.localStorage.user);
        let detail_user = jwt_decode(currentUser)
        if(currentUser  && detail_user.data.role === 1) {
          next()
        } else {
          alert('คุณไม่มีสิทธิ์เข้าถึงหน้าเว็บนี้')
          router.go(-1)
          // next("/")
        }
      }
    },
    {
      path: '/SuperAdminHome',
      name: 'SuperAdminHome',
      component: SuperAdminHome,
      beforeEnter(to, from, next) {
      let currentUser = JSON.parse(window.localStorage.user);
      let detail_user = jwt_decode(currentUser)
      if(currentUser && detail_user.data.role === 2) {
        next()
      } else {
        alert('คุณไม่มีสิทธิ์เข้าถึงหน้าเว็บนี้')
        // next("/")
        router.go(-1)
      }
    }
  },
    {
      path: '/log',
      name: 'Log',
      component: Log,
      beforeEnter(to, from, next) {
      let currentUser = JSON.parse(window.localStorage.user);
      let detail_user = jwt_decode(currentUser)
      if(currentUser && detail_user.data.role === 2) {
        next()
      } else {
        alert('คุณไม่มีสิทธิ์เข้าถึงหน้าเว็บนี้')
        // next("/")
        router.go(-1)
      }
    }
  },
    {
      path: '/verify',
      name: 'Verify',
      component: verifies,
      beforeEnter(to, from, next) {
      let currentUser = JSON.parse(window.localStorage.user);
      let detail_user = jwt_decode(currentUser)
      if((currentUser && detail_user.data.role === 2) || (currentUser && detail_user.data.role === 1)) {
        next()
      } else {
        alert('คุณไม่มีสิทธิ์เข้าถึงหน้าเว็บนี้')
        // next("/")
        router.go(-1)
      }
    }
  },
    {
      path: '/changeData',
      name: 'changeData',
      component: changeData,
      beforeEnter(to, from, next) {
      let currentUser = JSON.parse(window.localStorage.user);
      let detail_user = jwt_decode(currentUser)
      if((currentUser && detail_user.data.role === 2) || (currentUser && detail_user.data.role === 1)) {
        next()
      } else {
        alert('คุณไม่มีสิทธิ์เข้าถึงหน้าเว็บนี้')
        // next("/")
        router.go(-1)
      }
    }
  },
  {
    path: '*',
    name: 'MyComponent',
    component: MyComponent
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
