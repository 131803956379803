<template>
  <div class="Home">
    <div class="break">
      <v-text-field label="ค้นหาเมนูอาหาร" v-model="search"
      ></v-text-field>
    </div>
    <h1 id="Not"></h1>
    <div class="container">

    <v-card
      width="200px"
      @click.stop="showMenu(food.Food_Id, food.Food_Name, food.Owner, food.cover_img)"
      v-for="food in FoodList"
      :key="food.list_id"
      class="ma-5"
    >
      <!-- <v-img class="white--text align-end" height="200px" :src="require('@/assets/' + food.path)"> -->
      <v-img class="white--text align-end" height="200px" :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + food.cover_img">
      <!-- <v-img :src="require('@/assets/' + food.path)" style="width: 100%; height: 300px;" /> -->

      <!-- <v-img class="white--text align-end" height="200px" :src="food.path"> -->

      </v-img>

      <v-card-title>{{ food.Food_Name }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>{{ food.Description }}</v-card-text>
    </v-card>
    </div>

    <v-dialog v-model="dialog" scrollable max-width="50%">
      <v-card>
        <div class="image_slide">
          <v-carousel
            cycle
            height="300"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in food_img"
              :key="i"
              style="text-align: center;"
            >
              <img :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + item.path" style="width: 100%; height: 300px;" />
            </v-carousel-item>
          </v-carousel>
        </div>
         <v-card-title>
          {{ food_name }}
        <v-spacer></v-spacer>
                 <ion-icon name="book-outline"></ion-icon>
         
         <span style="font-size:12px;color: grey;">{{ visitor }}</span>
          <v-spacer></v-spacer>

        สูตรโดย : 
        {{ Owner }}
          </v-card-title>
        <v-divider></v-divider>
        <v-card-text height="100px">
          <v-card-subtitle><h3>วัตถุดิบที่จำเป็น</h3></v-card-subtitle>
          <ol>
            <li v-for="recipes in recipe" :key="recipes.Recipe_Id">
              {{ recipes.used_name }}
            </li>
          </ol>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle><h3>วิธีทำ</h3></v-card-subtitle>
          <ol>
            <li v-for="howtos in howto" :key="howtos.How_Id">
              {{ howtos.Descriptions }}
            </li>
          </ol>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle><h3>วีดีโอประกอบ</h3></v-card-subtitle>
          <div class="test">
            <div class="" v-if="video.length == 0">
            <h2>Video invalid</h2>
          </div>
          <div class="test" v-for="clip in video" :key="clip.Recipe_Id" v-else>
            <iframe v-if="dialog"
              width="560"
              height="300"
              :src="'https://food.makecodeonline.com/Tontan_Api/Admin/' + clip.path"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <p style="color: Silver;">แก้ไขล่าสุดเมื่อ : {{ updated_at }}</p>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="download">
            ดาวน์โหลด
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ปิดหน้าต่าง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </v-app> -->
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default {
  name: "Home",
  data() {
    return {
      dialog: false,
      Foodlists: [],
      food_name: "",
      recipe: [],
      howto: [],
      food_img: [],
      search: "",
      video: [],
      Owner: "",
      visitor: 0,
      created_at: null,
      updated_at: null,
      base64: null,
      cover_img: null,
    };
  },
  mounted() {
    axios
      // .get("https://food.makecodeonline.com/Tontan_Api/overall/index2.php")
      .get("https://food.makecodeonline.com/Tontan_Api/Beverage/beverage.php") 
      .then((res) => {
        // console.log(res.data.response);
        this.Foodlists = res.data.response;
      })
      .catch((err) => {
        console.log(err.response.data.message);
        let notfound = document.querySelector("#Not");
        notfound.innerHTML = "ไม่พบรายการอาหารในหมวดหมู่เครื่องดื่ม";
      });
  },
  methods: {
       getBase64ImageFromURL() {
      return new Promise((resolve, reject) => {
        var img = new Image();
        // img.setAttribute("crossOrigin", "anonymous");
        var co = "https://api.codetabs.com/v1/proxy?quest=";
        img.src =
          co +
          "https://food.makecodeonline.com/Tontan_Api/Admin/" +
          this.cover_img;
        img.crossOrigin = "Anonymous";

        img.onload = () => {
          var canvas = document.createElement("canvas");

          var ctx = canvas.getContext("2d");
          // ctx.drawImage(img, 0, 0);
          var imgWidth = img.width;
          var imgHeight = img.height;
          var scaleX = 1;
          var scaleY = 1;
          if (imgWidth > canvas.width) scaleX = canvas.width / imgWidth;
          if (imgHeight > canvas.height) scaleY = canvas.height / imgHeight;
          var scale = scaleY;
          if (scaleX < scaleY) scale = scaleX;
          if (scale < 1) {
            imgHeight = imgHeight * scale;
            imgWidth = imgWidth * scale;
          }
          var dx = (canvas.width - imgWidth) / 2;
          var dy = (canvas.height - imgHeight) / 2;

          ctx.beginPath();
          ctx.rect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = "white";
          ctx.fill();

          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            dx,
            dy,
            imgWidth,
            imgHeight
          );

          var dataURL = canvas.toDataURL("image/jpeg");

          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };
      });
    },
    async download() {
      var arr = this.recipe.map((se) => {
        return se.used_name;
      });
      var arr2 = this.howto.map((se) => {
        return se.Descriptions;
      });
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-MediumItalic.ttf",
        },
        Sarabun: {
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Bold.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-BoldItalic.ttf",
        },
      };

      var dd = {
        content: [
          {
            alignment: "justify",
            columns: [
              {
                stack: [
                  { text: this.food_name, style: "header" },
                  {
                    ul: [
                      { text: "สูตรอาหารโดย " + this.Owner, listType: "none" },
                    ],
                  },
                ],
              },
              {
                image: await this.getBase64ImageFromURL("myimage.jpg"),
                width: 200,
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 517,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
          {
            margin: [0, 20],
            style: "tableExample",
            table: {
              widths: [250, 250],
              headerRows: 1,
              body: [
                [
                  {
                    border: [false, false, false, true],
                    text: "วัตถุดิบที่สำคัญ",
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                    background: "#38383a",
                    color: "white",
                  },
                  {
                    border: [false, false, false, true],
                    text: "ขั้นตอนการทำ",
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                    background: "#38383a",
                    color: "white",
                  },
                ],
                [
                  {
                    ol: arr,
                    lineHeight: 1.5,
                  },
                  {
                    ol: arr2,
                    lineHeight: 1.5,
                  },
                ],
              ],
            },
          },
        ],
        styles: {
          header: {
            fontSize: 22,
            bold: true,
          },
          lef: {
            alignment: "left",
          },
          cen: {
            alignment: "center",
          },
          righ: {
            alignment: "right",
          },
        },
        defaultStyle: {
          font: "Sarabun",
          columnGap: 20,
        },
      };

      pdfMake.createPdf(dd).download("สูตร" + this.food_name);
    },
    async showMenu(id, name, own, path) {
      this.dialog = true,
      this.cover_img = path
        await axios
          .post(
            "https://food.makecodeonline.com/Tontan_Api/overall/showMenu.php",
            qs.stringify({ id: id })
          )
          .then((res) => {
            this.food_name = name
            this.Owner = own
            this.recipe = res.data.response;
            this.food_img = res.data.img;
            this.howto = res.data.howto;
            this.video = res.data.video;
                      this.created_at = res.data.response[res.data.response.lengtศศศh - 3];
          this.updated_at = res.data.response[res.data.response.length - 2];
          this.visitor = res.data.response[res.data.response.length-1]
          this.recipe.splice(-3)

          })
          .catch(() => {
            // console.log(err.response.data.message);
              this.$swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด"
          })
          });
    },
    clear() {
      this.dialog = false;
      this.recipe = [],
      this.food_name = null,
      this.howto = [],
      this.food_img = []
      this.video = null
    }
  },
  computed: {
    FoodList: function(){
      return this.Foodlists.filter((name) => {
        return name.Food_Name.match(this.search)
      })
    }
  },
};
</script>
<style lang="scss" scoped>
// .Home {
//   display: flex;
//   flex-wrap: wrap;
// }
// .break {
//   flex-basis: 100%;
//   padding-left: 30%;
// }
// .image_slide {
//   height: 500px;
// }
// @media (max-width: 800px) {
//   .Home {
//     flex-direction: column;
//   }
// }
// .test {
//   margin: 1% 11%;
// }
// #Not {
//   vertical-align: middle;
//   margin: 50px;
//   color: green;
// }
.Home {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // width: 100vw;
}
.break {
  position: relative;
  // background: #f5f524;
  // width: 50px;
  text-align: center;
  padding: 0;
}
.break v-text-field {
  position: absolute;
  left: 0;
  width: 50em;
}
.container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1;
  flex-shrink: 1;
  // background: #f5f524;
  margin: 0;
  padding: 0;
}
#Not {
  display: none;
}
// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
// .Home {
//   display: flex;
//   flex-wrap: wrap;
// }
// .break {
//   flex-basis: 100%;
//   padding-left: 30%;
// }
// .image_slide {
//   height: 500px;
// }
// .test {
//   margin: 1% 11%;
// }
@media screen and (min-width: 320px) and (max-width: 480px){
  .Home {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .break {
    width: 50vw;
    position: relative;
    padding: 0;
    margin: 0.4em auto;
  }
  .break v-text-field {
    position: absolute;
    left: 0;
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
