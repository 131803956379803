import axios from 'axios';
import qs from "qs";


const API_URL = 'https://food.makecodeonline.com/Tontan_Api/v1/';
// const API_URL = 'http://localhost/api-jwt/v1/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login_api.php', 
      qs.stringify({ email: user.email, password: user.password })
      // {
      //   email: user.email,
      //   password: user.password
      // }
      )
      .then(response => {
        // console.log("Success")
        // console.log(response.data.jwt)
        if (response.data.jwt) {
          localStorage.setItem('user', JSON.stringify(response.data.jwt));
        }

        return response.data.jwt;
        // return response.data;
      });
  }

  logout(id) {
    axios.post(API_URL + 'logout_api.php', qs.stringify({id: id}))
    .then(() => {
      localStorage.removeItem('user');
    })
  }

  register(user) {
    return axios.post(API_URL + 'create_user_api.php', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
