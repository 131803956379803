<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="8" sm="4">
          <v-text-field
            v-model="first"
            label="Name"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="8" sm="4">
          <v-text-field
            v-model="last"
            label="email"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" sm="4">
          <v-text-field
            v-model="first"
            label="password"
            outlined
          ></v-text-field>
        </v-col> 
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "changeData",
  data: () => ({
    first: "John",
    last: "Doe",
  }),
};
</script>

<style lang="scss" scoped></style>
