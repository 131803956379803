<template>
  <div class="main">
    <div class="text-center">
      <v-overlay :value="loading" :z-index="250">
        <v-progress-circular
          :size="70"
          :width="7"
          :z-index="250"
          color="amber"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <v-app>
      <!-- <div class="header"> -->
      <v-app-bar app color="green darken-3" dark width="100vw">
        <div class="d-flex align-center">
          <!-- <div class="toggle" v-show="isY"> -->
          <div class="toggle">
            <ion-icon name="menu-outline" class="open"></ion-icon>
            <ion-icon name="close-outline" class="close"></ion-icon>
          </div>
          <div class="banner">
            <router-link
              to="/"
              style="text-decoration: none; color: inherit;"
              class="d-flex"
            >
              <v-img
                v-show="isY"
                alt="Vuetify Logo"
                class="ml-12 mr-2 logo"
                contain
                src="../assets/unnamed.png"
                transition="scale-transition"
                style="border-radius: 50%; background-color: white; width: 50px; height: 50px;"
              />
              <v-img
                v-show="!isY"
                alt="Vuetify Logo"
                class="ml-12 mr-2 logo"
                contain
                src="../assets/unnamed.png"
                transition="scale-transition"
                style="border-radius: 50%; background-color: white; width: 30px; height: 30px; margin: 13px; left: 1px;"
              />
              <h2
                style="text-align: center; padding-top: 0.75%; text-shadow: 2px 2px #82c065;"
              >
                ฐานข้อมูลอาหารคาวหวานจากตาลโตนดเมืองเพชร
              </h2>
            </router-link>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div class="login" v-if="login">
          <v-btn text @click="signin()" v-if="isY">
            <v-icon class="white--text text-decoration-none"
              >mdi-account-key</v-icon
            >
          </v-btn>
          <v-btn text @click="signin()" v-else>
            <v-icon class="white--text text-decoration-none" dense
              >mdi-account-key</v-icon
            >
          </v-btn>
        </div>
        <div class="" v-else>
          <!-- <v-btn color="warning" dark @click="setting">
              Setting
            </v-btn> -->
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark v-bind="attrs" v-on="on">
                Personal
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <!-- <v-list-item-avatar>
              <img
                src="https://cdn.vuetifyjs.com/images/john.jpg"
                alt="John"
              >
            </v-list-item-avatar> -->

                  <v-list-item-content>
                    <v-list-item-title>{{ name_show }}</v-list-item-title>
                    <v-list-item-subtitle>{{ role_user }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <!-- <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn> -->
                    No : {{ id }}
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-container class="spacing-playground pa-6" fluid>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="10">
                    <v-text-field
                      v-model="name_user"
                      label="Name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="10">
                    <v-text-field
                      v-model="email_update"
                      label="email"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="10">
                    <v-text-field
                      v-model="password_update"
                      label="password"
                      type="password"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="10">
                    <v-text-field
                      v-model="password_confirm"
                      label="confirm-password"
                      type="password"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="message"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable messages</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="hints"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable hints</v-list-item-title>
          </v-list-item>
        </v-list> -->
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <!-- <v-btn text @click="menu = false">
                  Cancel
                </v-btn> -->
                <v-btn color="primary" text @click="personal">
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn @click="logOut()" color="success" text
            ><v-icon>mdi-logout</v-icon></v-btn
          >
          <!-- </router-link> -->
        </div>
      </v-app-bar>
      <!-- </div> -->
      <!-- <div class="navigation" v-show="isY"> -->
      <div class="navigation">
        <ul>
          <li class="list">
            <b></b>
            <b></b>
            <router-link to="/">
              <span class="icon"
                ><ion-icon name="home-outline"></ion-icon
              ></span>
              <span class="title">หน้าแรก</span>
              <!-- </a> -->
            </router-link>
          </li>
          <li class="list">
            <b></b>
            <b></b>
            <router-link to="/overall">
              <span class="icon"
                ><ion-icon name="file-tray-stacked-outline"></ion-icon
              ></span>
              <span class="title">หน้ารวม</span>
            </router-link>
          </li>
          <li class="list">
            <b></b>
            <b></b>
            <router-link to="/food">
              <span class="icon"
                ><ion-icon name="pizza-outline"></ion-icon
              ></span>
              <span class="title">อาหารคาว</span>
            </router-link>
          </li>
          <li class="list">
            <b></b>
            <b></b>
            <router-link to="/sweet">
              <span class="icon"
                ><ion-icon name="fast-food-outline"></ion-icon
              ></span>
              <span class="title">อาหารหวาน</span>
            </router-link>
          </li>
          <li class="list">
            <b></b>
            <b></b>
            <router-link to="/beverage">
              <span class="icon"
                ><ion-icon name="beer-outline"></ion-icon
              ></span>
              <span class="title">เครื่องดื่ม</span>
            </router-link>
          </li>
          <li class="list">
            <b></b>
            <b></b>
            <router-link to="/product">
              <span class="icon"
                ><ion-icon name="medkit-outline"></ion-icon
              ></span>
              <span class="title">ผลิตภัณฑ์</span>
            </router-link>
          </li>
          <li class="list" v-show="admin === 1">
            <b></b>
            <b></b>
            <router-link to="/addfood">
              <span class="icon"
                ><ion-icon name="file-tray-stacked-outline"></ion-icon
              ></span>
              <span class="title">หน้าแรก</span>
            </router-link>
          </li>
          <li class="list" v-show="admin === 1">
            <!-- <li class="list" v-if="admin === 1"> -->
            <b></b>
            <b></b>
            <router-link to="/report">
              <span class="icon"
                ><ion-icon name="newspaper-outline"></ion-icon
              ></span>

              <span class="title">รายงาน</span>
            </router-link>
          </li>
          <li class="list" v-show="admin === 2">
            <!-- <li class="list" v-if="admin === 1"> -->
            <b></b>
            <b></b>
            <router-link to="/SuperAdminHome">
              <span class="icon"
                ><ion-icon name="people-outline"></ion-icon
              ></span>

              <span class="title">ผู้ใช้งาน</span>
            </router-link>
          </li>
          <li class="list" v-show="admin === 2">
            <!-- <li class="list" v-if="admin === 1"> -->
            <b></b>
            <b></b>
            <router-link to="/log">
              <span class="icon"
                ><ion-icon name="file-tray-full-outline"></ion-icon
              ></span>

              <span class="title">การเข้าสู่ระบบ</span>
            </router-link>
          </li>
        </ul>
      </div>

      <v-main>
        <div class="content">
          <router-view />
        </div>
      </v-main>
      <!-- <v-bottom-navigation
    v-model="value"
    :background-color="color"
    dark
    shift
  >
    <v-btn>
      <span>Video</span>

      <v-icon>mdi-television-play</v-icon>
    </v-btn>

    <v-btn>
      <span>Music</span>

      <v-icon>mdi-music-note</v-icon>
    </v-btn>

    <v-btn>
      <span>Book</span>

      <v-icon>mdi-book</v-icon>
    </v-btn>

    <v-btn>
      <span>Image</span>

      <v-icon>mdi-image</v-icon>
    </v-btn>
  </v-bottom-navigation> -->
    </v-app>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import axios from "axios";

export default {
  name: "Admin",
  data: () => ({
    search: "",
    admin: 0,
    searchAble: true,
    login: true,
    id: 0,
    name_show: null,
    name_user: null,
    role_user: null,
    email_update: null,
    password_update: null,
    password_confirm: null,
    loading: false,
  }),
  mounted() {
    var list = document.querySelectorAll(".list");
    // check user type
    var tmpUser = this.$store.state.auth.user;
    if (this.$store.state.auth.user != null) {
      this.tmpUser = jwt_decode(tmpUser);
      this.admin = this.tmpUser.data.role;
      // alert(this.admin)
      this.login = this.tmpUser.data.loggedIn;
      this.id = this.tmpUser.data.id;
      this.name_show = this.tmpUser.data.name;
      this.name_user = this.tmpUser.data.name;
      this.email_update = this.tmpUser.data.email;
      if (this.tmpUser.data.role == 1) {
        this.role_user = "Admin";
      } else if (this.tmpUser.data.role == 2) {
        this.role_user = "Super Admin";
      }
    }

    let menuToggle = document.querySelector(".toggle");
    let navigation = document.querySelector(".navigation");
    let conten = document.querySelector(".content");
    let mainBody = document.querySelector(".main");
    var ac = window.localStorage;
    var acty = Number(ac.getItem("act"));
    menuToggle.onclick = function() {
      menuToggle.classList.toggle("active");
      navigation.classList.toggle("active");
      conten.classList.toggle("active");
      mainBody.classList.toggle("active")
    };
    list[acty].className = "list active";
    // for (let i = 0; i < 6; i++) {
    for (let i = 0; i < list.length; i++) {
      // let vm = this;
      list[i].onclick = function() {
        ac.setItem("act", i);
        let j = 0;
        while (j < list.length) {
          list[j++].className = "list";
        }
        list[i].className = "list active";
        navigation.className = "navigation"
        menuToggle.className = "toggle"
        mainBody.className = "main"
      };
    }
  },
  methods: {
    signin() {
      this.login = false;
      this.setLayout("login-layout");
      this.$router.push("/login");
    },
    setLayout(layout) {
      this.$store.commit("SET_LAYOUT", layout);
    },
    setting() {
      this.$router.push("/verify");
    },
    logOut() {
      this.$store.dispatch("auth/logout", {
        id: this.tmpUser.data.id,
      });
      this.admin = this.tmpUser.data.role;
      this.admin = this.tmpUser.data.loggedIn;
      this.login = true;
      this.setLayout("user-layout");
      this.$swal
        .fire({
          icon: "success",
          title: "ท่านได้ออกจากระบบแล้ว",
        })
        .then(() => {
          this.$router.push("/");
        });
    },
    personal() {
      this.loading = true;
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("name", this.name_user);
      formData.append("email", this.email_update);
      if (
        this.password_update != null &&
        this.password_update === this.password_confirm
      ) {
        formData.append("password", this.password_confirm);
      } else {
         this.$swal.fire({
            icon: "error",
            title: "แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          })
          .then(() => {
            location.reload()
          })
          return
      }
      axios
        .post(
          "https://food.makecodeonline.com/Tontan_Api/Admin-SuperAdmin/update_personal.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        // .then((res) => {
        //   this.loading = false;
        //   alert(res.data.message);
        //   this.$router.go();
        // })
        .then(() => {
          this.loading = false;
          this.$swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลสำเร็จ"
          })
          .then(() => {
            location.reload()
          })
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    $route(to) {
      // console.log(to.path);
      let l = document.querySelectorAll(".list");
      if (to.path === "/") {
        // this.searchAble = false;
        l[0].className = "list active";
      }
      for (let b = 1; b < l.length; b++) {
        l[b].className = "list";
      }
    },
    admin: (val) => {
      var listAdmin = document.querySelectorAll(".list");
      if (val === 1) {
        for (let g = 0; g < listAdmin.length; g++) {
          let h = 0;
          while (h < listAdmin.length) {
            listAdmin[h++].className = "list";
          }
          listAdmin[6].className = "list active";
        }
      } else if (val === 2) {
        for (let g = 0; g < listAdmin.length; g++) {
          let h = 0;
          while (h < listAdmin.length) {
            listAdmin[h++].className = "list";
          }
          listAdmin[8].className = "list active";
        }
      }
    },
  },
  computed: {
    // currentRouteName() {
    //   alert(this.$route.name);
    //   return this.$route.name;
    // },
    isY() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Nunito");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  min-height: 100vh;
  background: #fff;
}
.content {
  width: 90em;
  // width: 90rem;
  margin: 0 auto;
  // margin-left: 6em;
  // margin-left: 6%;
  text-align: center;
  transition: margin-left 2s;
}
.content.active {
  width: 90em;
  // width: 90rem;
  margin: 0 auto;
  // margin-left: 16%;
  margin-left: 16em;
  // background-color: red;
  text-align: center;
}
.navigation {
  position: fixed;
  top: 47px;
  left: 0;
  bottom: 0;
  width: 4em;
  // width: 70px;
  // width: 3.5%;
  border-radius: 10px;
  box-sizing: initial;
  border-left: 5px solid #388e3c;
  background: #388e3c;
  transition: 0.5s;
  overflow-x: hidden;
  z-index: 1;
}
.navigation.active {
  width: 220px;
}
.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 5px;
  padding-top: 40px;
}
.navigation ul li {
  position: relative;
  list-style: none;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.navigation ul li.active {
  background: #fff;
}
.navigation ul li b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}
.navigation ul li b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  background: #388e3c;
}
.navigation ul li b:nth-child(2) {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}
.navigation ul li b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  background: #388e3c;
}
.navigation ul li.active b:nth-child(1),
.navigation ul li.active b:nth-child(2) {
  display: block;
}
.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #fff;
}
.navigation ul li.active a {
  color: #333;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.navigation ul li a .icon ion-icon {
  font-size: 1.5em;
}
.navigation ul li a .title {
  position: relative;
  display: block;
  padding-left: 10px;
  height: 60px;
  line-height: 60px;
  white-space: normal;
}
.toggle {
  position: fixed;
  top: 7px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle ion-icon {
  position: absolute;
  color: #fff;
  font-size: 34px;
  display: none;
}
.toggle ion-icon.open,
.toggle.active ion-icon.close {
  display: block;
}
.toggle ion-icon.close,
.toggle.active ion-icon.open {
  display: none;
}
.v-progress-circular {
  margin: 1rem;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
  }
  .header {
    width: 100px;
    height: 100px;
    background-color: #388efc;
    z-index: 10;
  }
  .banner {
    position: relative;
    width: 15em;
    padding: 0;
    margin: auto;
  }
  .logo {
    position: absolute;
    left: -3em;
    top: -18%;
  }
  h2 {
    font-size: 12px;
    width: 50vw;
    margin-left: 30%;
  }
  .content {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
  }
  .content.active {
    margin: 0 auto;
    width: 100vw;
    // height: 90vh;
    // overflow: hidden;
  }
  .toggle {
    position: fixed;
    top: 7px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #388efc;
  }
  .toggle ion-icon {
    position: absolute;
    color: #fff;
    font-size: 34px;
    display: none;
  }
  .toggle ion-icon.open,
  .toggle.active ion-icon.close {
    display: block;
  }
  .toggle ion-icon.close,
  .toggle.active ion-icon.open {
    display: none;
  }
  .navigation{
    width: 0;
    background-color: #388e3c;
    border-radius: 10px;
    border-left: 0;
  }
  .navigation.active {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
    top: 47px;
    left: 0;
    background-color: rgb(56, 142, 60);
    background-color: rgba(56, 142, 60, 0.9);
    overflow-x: hidden;
    text-align: center;
  }
  .main.active {
    overflow: hidden;
  }
}
</style>
