import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import vuetify from './plugins/vuetify'
import { store } from './store'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueSweetalert2 from 'vue-sweetalert2';


Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]
Vue.use(VueSweetalert2)
import "sweetalert2/dist/sweetalert2.min.css"


// new Vue({
//   el: '#app',
//   router,
//   store,
//   vuetify,
//   components: { App },
//   template: '<App/>'
// })
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
