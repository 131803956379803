<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
import Admin from './Layouts/Admin'
import User from './Layouts/User'
import Login from './Layouts/Login'

export default {
  computed: {
    layout () {
      return this.$store.getters.layout
    }
  },
  components: {
    'admin-layout': Admin,
    'user-layout': User,
    'login-layout': Login
    // define as many layouts you want for the application
  },
  methods: {
    setLayout(layout) {
      this.$store.commit("SET_LAYOUT", layout);
    },
  },
}
</script>
