<template>
  <div id="body">
   <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary" style="margin-top: 12px;" @click="downloadExcel">ดาวน์โหลด</v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="logData"
      :search="search"
    ></v-data-table>
  </v-card>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from 'xlsx'

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: "ลำดับที่",
          align: "center",
          sortable: true,
          value: "No",
        },
        { text: "เลขประจำตัวผู้ใช้งาน", value: "id" },
        { text: "ชื่อผู้ใช้งาน", value: "name" },
        { text: "อีเมล", value: "email" },
        { text: "เวลาเข้าสู่ระบบ", value: "login_time" },
        { text: "เวลาออกจากระบบ", value: "logout_time" },
        { text: "หมายเลขเครื่อง", value: "ip_address" },
        { text: "ประเภทผู้ใช้งาน", value: "role_name" },
      ],
      logData:[],
    };
  },
  mounted() {
    axios
      .get("https://food.makecodeonline.com/Tontan_Api/SuperAdmin/log.php")
      .then((res) => {
        this.logData = res.data.response;
      })
      .catch((err) => {
        console.log(err.getMessage());
      });
  },
  methods: {
    downloadExcel() {
      const dataWS = XLSX.utils.json_to_sheet(this.logData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb,'Logfile.xlsx')
    }
  }
};
</script>

<style lang="scss" scoped>
#body {
  width: 88rem;
  margin-top: 1.5%;
}
</style>
